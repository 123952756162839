$(function() {


  $(window).on('load',function(){
    var n = 0,
    o = 0;
    $(window).on("scroll", function () {
      o = $(this).scrollTop(), 
      n < o ? 200 <= o && ($("header").addClass("active")) 
      : o - n < -10 && ($("header").removeClass("active")), n = o

      const header = $('header');
      const currentPos = $(window).scrollTop();

      // 上スクロール時
      if (currentPos > 200) {
        header.addClass('shadow');
      } else {
        header.removeClass('shadow');
      }
    });
  });

  //変数の設定
  var $body = $('body');

  //スクロール量を保存
  var scrollTop;

  //スクロールを固定
  function bodyFixedOn() {
    scrollTop = $(window).scrollTop();

    $body.css({
      position: 'fixed',
      top: -scrollTop,
      'overflow-y': 'hidden'
    });
  }

  //スクロールの固定を解除
  function bodyFixedOff() {
    $body.css({
      position: '',
      top: '',
      'overflow-y': ''
    });
    
  $(window).scrollTop(scrollTop);
  }

  let icon = $("#header-icon");
  let menu = $(".header-humberger");

  // ハンバーガーメニュー
  icon.click(function(){
    $(this).toggleClass('active');
    menu.slideToggle();

    if (icon.hasClass('active')) {
      bodyFixedOn();
    } else {
      bodyFixedOff();
    }
  });

});